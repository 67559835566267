import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { graphql } from "gatsby";
import { Button } from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import seoData from "../../components/SEO/data";
import NoScript from "../../components/NoScript";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import {
	ALL_PHOTOS_CONTENT,
	ALL_PHOTOS_CONTENT_MAX_UPDATE,
} from "../../queries/queries.graphql";

export const query = graphql`
	query PhotoAlbumsPage($pagePath: String!) {
		hasura {
			...AllPhotosContent
			...PageSettings
		}
	}
`;

export default function PhotoAlbum({ data }) {
	const { theme } = useThemeContext();

	function makeData(data = [], type) {
		return data.map(({ title_short, title_full, slug, main_image_preview, main_image, page_title_short }) => {
			const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

			return {
				slug: formatURL(`media/${type}/${slug}`),
				image,
				title: title_short || title_full || page_title_short,
			};
		});
	}
	const url = "photoalbums";
	const limit = 8;
	const maxUpdated = new Date(get(data, "hasura.v_all_media_photos_aggregate.aggregate.max.updated_at", new Date()));

	const [photoalbums, setPhotoalbums] = useState(makeData(get(data, "hasura.v_all_media_photos", []), "photos"));
	const [offset, setOffset] = useState(limit);
	const seo = get(data, "hasura.page_settings[0]", {});

	const photoAlbumsOptions = {
		displayName: "PhotoalbumsQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const {
		loading: maxUpdateLoading,
		data: maxUpdateData,
		error: maxUpdateError,
	} = useQuery(
		ALL_PHOTOS_CONTENT_MAX_UPDATE,
		{
			...photoAlbumsOptions,
		},
	);

	const [loadMore, {
		called: loadMoreCalled,
		loading: loadMoreLoading,
		error: loadMoreError,
		data: loadMoreData,
	}] = useLazyQuery(
		ALL_PHOTOS_CONTENT,
		{
			variables: {
				limit,
				offset,
			},
			...photoAlbumsOptions,
		},
	);
	//
	const [loadPhotoalbums, {
		called: photoAlbumsCalled,
		loading: photoAlbumsLoading,
		data: photoAlbumsData,
		error: photoAlbumsError,
	}] = useLazyQuery(
		ALL_PHOTOS_CONTENT,
		{
			variables: {
				limit,
				offset: 0,
			},
			...photoAlbumsOptions,
		},
	);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_all_media_photos_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadPhotoalbums();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load PhotoalbumsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (photoAlbumsData && !photoAlbumsError) {
			setPhotoalbums(makeData(get(photoAlbumsData, "v_all_media_photos", photoalbums), "photos"));
			removePreloader(photoAlbumsCalled);
		} else if (photoAlbumsError) {
			console.error("Invalid load PhotoalbumsData", { photoAlbumsError });
			removePreloader();
		}
	}, [photoAlbumsLoading]);

	useEffect(() => {
		if (loadMoreCalled && !loadMoreError && !loadMoreLoading) {
			setPhotoalbums([...photoalbums, ...makeData(get(loadMoreData, "v_all_media_photos", []), "photos")]);
			setOffset(get(loadMoreData, "v_all_media_photos", []).length < limit ? null : offset + limit);
		} else if (loadMoreError) {
			setOffset(null);
		}

		removePreloader(loadMoreCalled);
	}, [loadMoreLoading]);

	return (
		<Pages url={url} entity={seo || get(seoData, "photoalbums_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Фоторепортажи"}
						pageContext={
							{
								item: {
									title_full: "Медиатека",
									slug: "media",
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Фоторепортажи"}>
						<ContentList items={photoalbums} className={"col-12 col-md-3 d-noscript-none"} style={{ width: "100%",
							height: "400px",
							objectFit: "cover" }}/>
						<NoScript>
							<ContentList items={photoalbums} className={"col-12 col-md-3"} style={{ width: "100%",
								height: "400px",
								objectFit: "cover" }}/>
						</NoScript>
					</TextBlock>
					<div className="row justify-content-center mb-5  d-noscript-none">
						<Button
							disabled={loadMoreLoading || !offset}
							loading={loadMoreLoading}
							onClick={() => loadMore({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Фоторепортажи"} />
			</div>
		</Pages>
	);
}

PhotoAlbum.propTypes = {
	data: PropTypes.object,
};
